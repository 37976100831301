<script setup>
import { ref } from 'vue';
import { useMetaStore } from '~/stores/meta.store'

const metaStore = useMetaStore();
const meta = ref({});
metaStore.getMetaTags()

meta.value = metaStore.metaGetter();
metaStore.setMeta(meta.value);

const sitemaps = ref([]);
sitemaps.value = [
    { name: "Sitemap", url: '/sitemap.xml' },
    { name: "Cities", url: '/cities.xml' },
    { name: "Listings", url: '/listings.xml' },
    { name: "Pages", url: '/pages.xml' },
];
</script>

<template>
    <LayoutAppHead></LayoutAppHead>
    <NuxtLayout name="mobile-base">
        <div class="my-14">
          <div v-for="sitemap in sitemaps" class="flex justify-center items-center border rounded-md mb-3 w-1/4 m-auto">
            <NuxtLink :to="sitemap.url" target="_blank" class="bg-white rounded-md  p-4 w-full text-center text-indigo-800 hover:bg-gray-100">{{ sitemap.name }}</NuxtLink>
          </div>
        </div>
    </NuxtLayout>
</template>